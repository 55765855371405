import { Box, Divider, Link, Paper, Typography } from '@mui/material'
import React from 'react'
import Address from './address'
import OpeningTimes from './openingTimes'

const Footer = () => {
  return (
    <Box>
      <Divider
        sx={{
          height: '5px',
          backgroundColor: 'brown',
          border: 'none'
        }}
      />
      <Box marginTop={2} display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"} sx={{
        flexDirection: {
          xs:
            "column", // Hide on small screens (tablet)
          sm:
            "row", // Hide on extra-small screens (mobile)
        },
        gap: {
          xs: 2,
          sm: 20
        }
      }}>
        <Box width={300}>
          <Address />
        </Box>
        <OpeningTimes />
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Box>
            <Typography variant='h5'>
              <Box display={"flex"} alignItems={"center"} marginBottom={2} gap={1}>
                Termine
              </Box>
            </Typography>
          </Box>
          <Box width={"250px"} sx={{
              wordWrap: 'normal',
              wordBreak: 'keep-all',
              hyphens: 'none'
            
          }}>
            Termine erfolgen zwingend nach telefonischer Anmeldung (keine Walk-in-Konsultationen) <br /> <br />
            Terminanfragen oder -verschiebungen nehmen wir nur telefonisch entgegen. Für alle weiteren Anliegen können Sie uns per E-Mail oder Telefon kontaktieren.
          </Box>
        </Paper>
      </Box>
      <Box marginTop={"auto"}>
        Copyright 2024 Praxis Seilermatte | <Link>Datenschutzerklärung</Link> | Designed by <Link href="https://github.com/Timeler1">Tim</Link> & <Link href="https://github.com/M4anuel">Manuel</Link>
      </Box>
    </Box>

  )
}

export default Footer
