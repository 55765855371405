import React from 'react'
import Data from "./summary.json"
import { Box, Paper, Typography, Button } from '@mui/material'
import OpeningTimes from '../../sharedComponents/openingTimes';
const Home = () => {
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `patientenformular.pdf`;
        link.href = "./patientenformular.pdf";
        link.click();
      };
    return (
        <Box
            minHeight={"100%"}
            sx={{
                backgroundImage: `url(/rooms/Empfang.JPG)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "auto",
                minHeight: "100vh"
            }}>

            <Box
                width="100%"
                top={0}
            >
                <Box
                    display="flex"
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    width="fit-content"
                    gap={20}
                    bgcolor="rgba(165, 42, 42, 0)"
                    p={5}
                    borderRadius={"5px"}
                    sx={{
                        flexDirection: {
                            xs: "column", // Hide on small screens (tablet)
                            sm: "row", // Hide on extra-small screens (mobile)
                        },
                    }}
                >

                    <Box display={"flex"} flexDirection={"column"} gap={2} >
                        <Box maxWidth={"30em"}>
                            <Paper elevation={5} sx={{
                                backgroundColor: "#ffffff80" // rgba(255,255,255,0.5) to hex
                            }}>
                                <Box p={2} sx={{
                                }}>
                                    <Typography variant="h5" marginBottom={2}>Über uns</Typography>
                                    {Data.summary.split('\n').map((paragraph, index) => (
                                        <React.Fragment key={index}>
                                            {paragraph}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                        <Box maxWidth={"30em"}>
                            <Paper elevation={5} sx={
                                {
                                    backgroundColor: "#ffffff80"
                                }
                            }>
                                <Box p={2} >
                                    <Typography variant="h5" marginBottom={2}>News</Typography>
                                    {Data.news.map(article => (
                                        <Box>
                                            <Box sx={{ fontWeight: 'medium' }} marginBottom={0.5}>{article.title}</Box>
                                            {article.body.split('\n').map((paragraph, index) => (
                                                <React.Fragment key={index}>
                                                    {paragraph}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Box alignItems={"top"}>
                            <OpeningTimes bg={"#ffffff80"} />
                        </Box>
                        <Box maxWidth={"30em"} alignItems={"top"} >
                            <Paper elevation={5} sx={
                                    {
                                        backgroundColor: "#ffffff80",
                                        padding:2
                                    }
                                }>
                                <Typography variant="h5" marginBottom={2}>Patientenformular</Typography>
                                    <Box>Falls Sie zum ersten mal bei uns sind, füllen Sie doch bitte das folgende Patientenformular hinsichtlich der neuen Datenschutzregelung vorgängig aus und bringen es zum Termin mit:</Box>
                                    <Box paddingTop={2}>                                    
                                        <Button onClick={onDownload} variant="contained" color="primary">
                                            Patientenformular
                                        </Button>   
                                    </Box>

                            </Paper>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default Home
